.App {
  text-align: center;
}

/*@font-face {*/
/*  font-family: "Oswald";*/
/*  src: url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);*/
/*}*/

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url('./assets/fonts/Oswald.ttf') format('truetype');
}

body {
  font-family: 'Oswald', sans-serif !important;
  /*text-transform: uppercase;*/
  letter-spacing: 1px;
  background: black;
}
